<template>
	<div class="container-modal form-modal" id="step5">
        <div v-if="$resize && $mq.above(601)" class="content-icon forgot" @mouseover="logoAnim">
			<lottie class="bird-logo active" :options="defaultOptionsLogo" :height="112" :width="112" v-on:animCreated="handleAnimationLogo"/>
        </div>
		<div class="container-holder">
			<div class="info-content" id="content">
				<div class="header-modal">
					<div class="title">{{ $t('register.title_step_5') }}</div>
				</div>
				<div class="container-content-modal">
					<div class="form-section-register">
						<div class="input-login-group-tbf w-mob-100" v-bind:class="{has_error: $v.card.card_number.$error || stripeError != '' || cardErrors.cardNumber, complete: card.card_number != '' && !$v.card.card_number.$error }" id="payment">
							<div class="label-input">
								<label style="min-width: 125px;">{{$t('subscription.card_number')}}</label>
								<div class="error-msg" v-if="cardErrors.cardNumber">{{ cardErrors.cardNumber }}</div>
								<div class="error-msg" v-else-if="stripeError != ''">{{ stripeError }}</div>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-card /></div>
								<div class="icon-right" v-if="card.card_number != ''" @click.stop="card.card_number = ''"><icon-close /></div>
								<input type="tel" :placeholder="$t('subscription.card_number_placeholder')" class="input-text" v-model="card.card_number" v-cardformat:formatCardNumber maxlength="19">
							</div>
						</div>

						<div class="input-login-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.card.card_expiration.$error || cardErrors.cardExpiry, complete: card.card_expiration != '' && !$v.card.card_expiration.$error }">
							<div class="label-input">
								<label>{{ $t('subscription.validity_date') }}</label>
								<div class="error-msg" v-if="cardErrors.cardExpiry">{{ cardErrors.cardExpiry }}</div>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-date /></div>
								<div class="icon-right" v-if="card.card_expiration != ''" @click.stop="card.card_expiration = ''"><icon-close /></div>
								<input type="tel" :placeholder="$t('subscription.validity_date_placeholder')" class="input-text" v-model="card.card_expiration" v-cardformat:formatCardExpiry maxlength="9">
							</div>
						</div>
						
						<div class="input-login-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.card.card_cvc.$error || cardErrors.cardCvc, complete: card.card_cvc != '' && !$v.card.card_cvc.$error}">
							<div class="label-input">
								<label>{{ $t('subscription.cvc') }}</label>
								<div class="error-msg" v-if="cardErrors.cardCvc">{{ cardErrors.cardCvc }}</div>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-password /></div>
								<div class="icon-right" v-if="card.card_cvc != ''" @click.stop="card.card_cvc = ''"><icon-close /></div>
								<input type="tel" :placeholder="$t('subscription.cvc_placeholder')" class="input-text" v-model="card.card_cvc" v-cardformat:formatCardCVC maxlength="4">
							</div>
						</div>
					</div>
					<div class="submit-form">
						<button id="buttonFinishPay" class="btn-tbf blue center" @click="goNextStep">
							<div class="loader"></div>
							<div class="text">{{$t('subscription.activate_account')}}</div>
						</button>
					</div>
					<div class="powered-by">
						<a href="https://stripe.com" target="_blank">
							<img src="/build/images/stripe-black.png">
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Lottie from 'vue-lottie';
	import * as animationDataLogo from '../../assets/animations/tbfdigital_logo.json';
	import IconClose from '../Icons/Close'
	import IconCard from "../Icons/Card"
	import IconDate from "../Icons/Date"
	import IconPassword from '../Icons/Password';
	import { required } from 'vuelidate/lib/validators'
	
	export default {
		data(){
			return {
				defaultOptionsLogo: {
					animationData: animationDataLogo.default,
					autoplay: false,
					loop: false,
				},
				finished: true,
				bird_noti: false,
				card:{
					card_number: '',
					card_expiration: '',
					card_cvc: ''
				},
				cardErrors: {},
				stripeError: ''
			}
		},
		props:{
			user: Object
		},
		components: {
			IconClose,
			IconCard,
			IconDate,
			IconPassword,
			Lottie
		},
		validations: {
			card: {
				card_number: {required},
				card_expiration: {required},
				card_cvc: {required}
			}
		},
		async mounted() {
			this.logoAnim();
		},
		methods: {
			goNextStep(){
				var buttonName = 'buttonFinishPay'
                var btnSubmit = document.getElementById(buttonName);
                var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
                btnSubmit.disabled = true

				this.cardErrors = {}
				this.stripeError = ''
				var cardError = false
				// validate card number
				if(!this.$cardFormat.validateCardNumber(this.card.card_number)){
					this.cardErrors.cardNumber = this.$t('validator.invalid_credit_card');
					cardError = true
				};

				// validate card expiry
				if (!this.$cardFormat.validateCardExpiry(this.card.card_expiration)) {
					this.cardErrors.cardExpiry = this.$t('validator.invalid_credit_card_date');
					cardError = true
				};

				// validate card CVC
				if (!this.$cardFormat.validateCardCVC(this.card.card_cvc)) {
					this.cardErrors.cardCvc = this.$t('validator.invalid_credit_card_cvc');
					cardError = true
				};

				if(!cardError){
					btnSubmit.classList.add('loading')
					btnSubmitLoader.classList.add('onProgress')
					btnSubmitText.innerHTML = this.$t('btn-submit.loading')
					
					axios.post('/payment', {
						number: this.card.card_number,
						exp_month: this.card.card_expiration.substring(0, 2),
						exp_year: this.card.card_expiration.substring(5, 9),
						cvc: this.card.card_cvc,
					})
					.then(({data}) => {
						btnSubmitLoader.classList.add('finish')
						setTimeout(()=>{
							btnSubmitText.innerHTML = this.$t('btn-submit.success')
							btnSubmit.classList.add('completed')
							btnSubmitLoader.classList.remove('onProgress', 'finish')
							btnSubmit.classList.remove('loading')
							setTimeout(()=>{
								btnSubmit.classList.remove('completed')
								this.$auth.fetch().then(() => { this.$router.push({ name: 'register-success'}) })
							}, 1000)
						}, 300)
					})
					.catch(error => {
						let errorData = error.response.data
						if(errorData.subscription && ['requires_source_action', 'requires_action'].includes(errorData.subscription.latest_invoice.payment_intent.status)){
							var sub = errorData.subscription;

							Stripe(process.env.VUE_APP_STRIPEPUBLISHABLE_KEY)
							.confirmCardPayment(sub.latest_invoice.payment_intent.client_secret)
							.then((result) => {
								if(result.error){
									btnSubmitLoader.classList.add('finish')
									setTimeout(()=>{
										btnSubmitText.innerHTML = this.$t('btn-submit.error')
										btnSubmit.classList.add('error')
										btnSubmitLoader.classList.remove('onProgress', 'finish')
										btnSubmit.classList.remove('loading')
										setTimeout(()=>{
											btnSubmit.classList.remove('error')
											this.stripeError = result.error.message;
											btnSubmitText.innerHTML = this.$t('subscription.activate_account')
										}, 1000)
									}, 300)
								}else{
									axios.post('/set-as-active')
									.then(({data}) => {
										btnSubmitLoader.classList.add('finish')
										setTimeout(()=>{
											btnSubmitText.innerHTML = this.$t('btn-submit.success')
											btnSubmit.classList.add('completed')
											btnSubmitLoader.classList.remove('onProgress', 'finish')
											btnSubmit.classList.remove('loading')
											setTimeout(()=>{
												btnSubmit.classList.remove('completed')
												this.$auth.fetch().then(() => { this.$router.push({ name: 'register-success'}) })
											}, 1000)
										}, 300)
									})
								}
							});
						}else{
							btnSubmitLoader.classList.add('finish')
							setTimeout(()=>{
								btnSubmitText.innerHTML = this.$t('btn-submit.error')
								btnSubmit.classList.add('error')
								btnSubmitLoader.classList.remove('onProgress', 'finish')
								btnSubmit.classList.remove('loading')
								setTimeout(()=>{
									btnSubmit.classList.remove('error')
									this.stripeError = error.response.data.message;
									btnSubmitText.innerHTML = this.$t('subscription.activate_account')
								}, 1000)
							}, 300)
						}
					})
					.finally(() => {
						setTimeout(()=>{
							btnSubmit.disabled = false
						}, 1500)
					})
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('subscription.activate_account')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			handleAnimationLogo(anim){
				this.anim_logo = anim;
				this.anim_logo.setSpeed(1);
			},
			logoAnim(){
				if(this.finished && !this.bird_noti){
					this.finished = false;
					this.anim_logo.play();
					setTimeout(() => {
						this.anim_logo.stop();
						this.finished = true;
					}, 5000);
				}
			}
		}
	}
</script>